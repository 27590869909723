import {GlobalWorkerOptions} from "pdfjs-dist"
import {useEffect, useState} from "react"
import {Button} from "@nextui-org/react"
import {Document, Page, pdfjs} from "react-pdf"

// import pdfjsLib from "pdfjs-dist"
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface PDFViewProps {
  pdfURL: string
}

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
}

const PDFViewer = ({pdfURL}: PDFViewProps) => {
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  console.log(pdfURL)

  function onDocumentLoadSuccess({numPages}: {numPages: number}): void {
    setNumPages(numPages)
  }

  return (
    <div>
      <Document
        file={pdfURL}
        onLoadSuccess={onDocumentLoadSuccess}
        // renderMode="canvas"
        options={options}
      >
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            className="mb-4 h-full w-fit rounded-md border border-gray-300 p-4"
          />

          //   horizontal line
        ))}
      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  )
}

export default PDFViewer
