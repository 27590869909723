import {getFirestore, collection, doc, setDoc} from "firebase/firestore"
import {app} from "../../firebaseConfig"

// write a doc to the leadgen collection
export async function writeLeadgenDoc(email: string) {
  const db = getFirestore(app)
  const leadgenRef = collection(db, "leadgen")
  const leadgenDoc = doc(leadgenRef, email)
  await setDoc(leadgenDoc, {email})
}
