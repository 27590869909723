import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import {NextUIProvider} from "@nextui-org/react"

import reportWebVitals from "./reportWebVitals"
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom"

import {helix} from "ldrs"

import {auth} from "./firebaseConfig"
import {
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth"
import Landing from "./routes/landing.tsx"
import Root from "./routes/root.tsx"
import Home from "./routes/home.tsx"

import Login from "./routes/login.tsx"
import ErrorPage from "./error-page"
import Logs from "./routes/logs.tsx"
import {CortexProvider} from "./utils/providers/cortexProvider"

// Confirm the link is a sign-in with email link.
// const auth = getAuth()

function AuthenticatedRoute({children}) {
  const [user, setUser] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  helix.register()

  // Default values shown

  React.useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn")
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation")
      }
      return signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          console.log("removing")
          window.localStorage.removeItem("emailForSignIn")
          setUser(result.user)
          setLoading(false)
          console.log("redirect is successful")

          // You can access the new user by importing getAdditionalUserInfo
          // and calling it with result:
          // getAdditionalUserInfo(result)
          // You can access the user's profile via:
          // getAdditionalUserInfo(result)?.profile
          // You can check if the user is new or existing:
          // getAdditionalUserInfo(result)?.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error(error)
        })
    } else {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        console.log("onAuthStateChanged is successful")
        setUser(currentUser)
        setLoading(false)
      })

      return () => unsubscribe()
    }
  }, [])

  if (loading)
    return (
      <div className="flex h-full w-full animate-appearance-out flex-col items-center justify-center gap-4">
        <l-helix size="130" speed="4.5" color="black"></l-helix>
        <p className="font-monaspaceNeon text-sm">LOADING CORTEX</p>
        <p className="text-xs">
          If loading persists, please navigate to{" "}
          <a href="/home" className="self-end text-xs underline">
            www.onyxindustrials.com/home
          </a>
        </p>
      </div>
    )
  return user ? children : <Navigate to="/login" />
}

const ExternalRedirect = ({to}) => {
  React.useEffect(() => {
    window.location.href = to
  }, [to])

  return null // Render children instead of nothing
}
// const currentURL = window.location.href
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      // currentURL === "https://www.onyxindustrials.com" ? (
      //   <Landing />
      // ) : (
      // <ExternalRedirect to="https://www.onyxindustrials.com/" />
      <Landing />
    ),
    // ),
    errorElement: <ErrorPage />,
  },
  // {
  //   // path: "/admin",
  //   element: <Root />,
  //   errorElement: <ErrorPage />,
  //   children: [
  //     {
  //       path: "/home",
  //       element: (
  //         <AuthenticatedRoute>
  //           <CortexProvider>
  //             <Home />
  //           </CortexProvider>
  //         </AuthenticatedRoute>
  //       ),
  //       errorElement: <ErrorPage />,
  //     },
  //     {
  //       path: "/logs",
  //       element: (
  //         <AuthenticatedRoute>
  //           <CortexProvider>
  //             <Logs />
  //           </CortexProvider>
  //         </AuthenticatedRoute>
  //       ),
  //       errorElement: <ErrorPage />,
  //     },
  //   ],
  // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <NextUIProvider>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </NextUIProvider>
)

// Shantanu's Dev Tag

// let asciiStyle = [
//   "font-size: 6px",
//   "font-weight: bold",
//   "font-family: monospace",
//   "display: flex",
// ].join(";")

// let logStyle = ["font-size: 16px"].join(";")

// let msg = `%cThis website was designed, programmed, and engineered by:
//  %c▄████████    ▄█    █▄       ▄████████ ███▄▄▄▄       ███        ▄████████ ███▄▄▄▄   ███    █▄
//   ███    ███   ███    ███     ███    ███ ███▀▀▀██▄ ▀█████████▄   ███    ███ ███▀▀▀██▄ ███    ███
//   ███    █▀    ███    ███     ███    ███ ███   ███    ▀███▀▀██   ███    ███ ███   ███ ███    ███
//   ███         ▄███▄▄▄▄███▄▄   ███    ███ ███   ███     ███   ▀   ███    ███ ███   ███ ███    ███
// ▀███████████ ▀▀███▀▀▀▀███▀  ▀███████████ ███   ███     ███     ▀███████████ ███   ███ ███    ███
//          ███   ███    ███     ███    ███ ███   ███     ███       ███    ███ ███   ███ ███    ███
//    ▄█    ███   ███    ███     ███    ███ ███   ███     ███       ███    ███ ███   ███ ███    ███
//  ▄████████▀    ███    █▀      ███    █▀   ▀█   █▀     ▄████▀     ███    █▀   ▀█   █▀  ████████▀

//     ▄█    ▄█    █▄       ▄████████  ▄█    █▄     ▄████████    ▄████████  ▄█
//     ███   ███    ███     ███    ███ ███    ███   ███    ███   ███    ███ ███
//     ███   ███    ███     ███    ███ ███    ███   ███    █▀    ███    ███ ███▌
//     ███  ▄███▄▄▄▄███▄▄   ███    ███ ███    ███  ▄███▄▄▄      ▄███▄▄▄▄██▀ ███▌
//     ███ ▀▀███▀▀▀▀███▀  ▀███████████ ███    ███ ▀▀███▀▀▀     ▀▀███▀▀▀▀▀   ███▌
//     ███   ███    ███     ███    ███ ███    ███   ███    █▄  ▀███████████ ███
//     ███   ███    ███     ███    ███ ███    ███   ███    ███   ███    ███ ███
// █▄ ▄███   ███    █▀      ███    █▀   ▀██████▀    ██████████   ███    ███ █▀
// ▀▀▀▀▀▀                                                        ███    ███
// `

// console.log(msg, logStyle, asciiStyle)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
