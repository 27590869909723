import React from "react"
import {twMerge} from "tailwind-merge"

interface DotBackgroundProps {
  bg?: string
  dotColor?: string
}

export function DotBackground({
  bg = "black",
  dotColor = "white",
}: DotBackgroundProps) {
  const blackDot =
    "h-full w-full items-center justify-center dark:bg-black bg-dot-black/[0.5]"
  const whiteDot =
    "h-full w-full items-center justify-center dark:bg-black bg-dot-white/[0.5]"
  const blackBg = twMerge(
    "pointer-events-none absolute bg-black inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)] dark:bg-black"
  )
  const whiteBg = twMerge(
    "pointer-events-none absolute bg-white inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)] dark:bg-black"
  )
  const slateBg = twMerge(
    "pointer-events-none absolute bg-onyx-slate inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)] dark:bg-black"
  )
  return (
    <div className={dotColor === "white" ? whiteDot : blackDot}>
      {/* Radial gradient for the container to give a faded look */}
      <div
        className={
          bg === "black" ? blackBg : bg === "slate" ? slateBg : whiteBg
        }
      ></div>
    </div>
  )
}
