// "use server";
// import * as admin from "firebase-admin";

import {initializeApp} from "firebase/app"
import {connectAuthEmulator, getAuth} from "firebase/auth"
import {connectStorageEmulator, getStorage} from "firebase/storage"
// import {connectFunctionsEmulator, getFunctions} from "firebase/functions"
import {getFirestore} from "firebase/firestore"
import {getAnalytics, isSupported} from "firebase/analytics"

// const firebaseConfig = {
//     apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
//     authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
//     measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDdDtcJIi8WxMrrD4ENYBWxndmDAtEhumc",
//   authDomain: "onyx-americannitrile.firebaseapp.com",
//   projectId: "onyx-americannitrile",
//   storageBucket: "onyx-americannitrile.appspot.com",
//   messagingSenderId: "765533690423",
//   appId: "1:765533690423:web:2774c2e37a24dcf152fdb2",
//   measurementId: "G-JJB92XQ98Q",
// }

// const firebaseConfig = {
//   apiKey: "AIzaSyD3esVjAJPcpISYK3OkFncMMueYkqqWZ1o",
//   authDomain: "onyx-labs-staging.firebaseapp.com",
//   projectId: "onyx-labs-staging",
//   storageBucket: "onyx-labs-staging.appspot.com",
//   messagingSenderId: "1087654509297",
//   appId: "1:1087654509297:web:5edd078e98d3e8733c4750",
// }

const FB_API_KEY = process.env.REACT_APP_FB_API_KEY
const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN
const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID
const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET
const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID
const FB_APP_ID = process.env.REACT_APP_FB_APP_ID

const firebaseConfig = {
  apiKey: FB_API_KEY,
  authDomain: FB_AUTH_DOMAIN,
  projectId: FB_PROJECT_ID,
  storageBucket: FB_STORAGE_BUCKET,
  messagingSenderId: FB_MESSAGING_SENDER_ID,
  appId: FB_APP_ID,
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
// export const auth = admin.auth();
// export const storage = admin.storage();
// export const db = admin.firestore();

// export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const storage = getStorage(app)
// export const functions = getFunctions(app);
export const db = getFirestore(app)
// export const analytics = isSupported().then((yes) =>
//     yes ? getAnalytics(app) : null
// );
// if (false) {
//     const ip = "127.0.0.1";
//     connectAuthEmulator(auth, `http://${ip}:9099`);
//     connectFirestoreEmulator(db, ip, 8080);
//     connectFunctionsEmulator(functions, ip, 5001);
//     connectStorageEmulator(storage, ip, 9199);
