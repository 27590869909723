import React from "react"
import Particles, {initParticlesEngine} from "@tsparticles/react"
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine"
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import {loadSlim} from "@tsparticles/slim" // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

export const TSParticle = () => {
  const [init, setInit] = React.useState(false)

  // this should be run only once per application lifetime
  React.useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine)
      //await loadBasic(engine);
    }).then(() => {
      setInit(true)
    })
  }, [])

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container)
  }

  const options: ISourceOptions = React.useMemo(
    () => ({
      background: {
        color: {
          value: "#313131",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "connect",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          connect: {
            distance: 100,
            opacity: 0.5,
          },
        },
      },
      fullScreen: {
        enable: false,
      },

      particles: {
        color: {
          value: ["#17B26A", "#FFB900"],
        },
        links: {
          color: "#ffffff",
          distance: 100,
          enable: true,
          opacity: 0.15,
          width: 1,
        },
        move: {
          direction: "Random",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            area: 1000,
            factor: 50,
          },
          value: 500,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: {min: 1, max: 2},
        },
      },
      detectRetina: true,
    }),
    []
  )

  if (init) {
    return (
      <>
        <Particles
          id="tsparticles"
          className="h-full w-full"
          style={{width: "100%", height: "100%"}}
          particlesLoaded={particlesLoaded}
          options={options}
        />{" "}
        <div
          className={`h-full w-full items-center justify-center bg-dot-white/[0.5] dark:bg-black dark:bg-dot-black/[0.2]`}
        ></div>
      </>
    )
  }
}
